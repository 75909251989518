*{
  color: #172649 !important;
}
label{
  color: #172649 !important;
}

button{
  background-color: #6ecf81 !important;
}
a{
  z-index: 5 !important;
}

.form-check-label{
  font-size: 13px !important;
}

.booked-date {
  background-color: #d3d3d3 !important; /* Light grey */
  color: #333 !important; /* Darker text */
  border-radius: 5px;
  pointer-events: none; /* Prevent selection */
  text-decoration: line-through;
}

.date-picker{
  margin-right: 10px;
}